.bang-gia-ph{
  .form-use-free{
    margin-bottom: 80px;

    .highlight{
      &::before{
        content: unset;
      }
      color: $color-yellow;
    }
    form{
      margin-top: 36px;
      .form-group{
        width: 24%;
        margin: 0 5px;
        @include media-breakpoint-down(md){
          width: 100%;
          margin:  0 ;
        }
        label.error{
          position: relative !important;
        }
      }
      input{
        width: 100%;
        height: 60px;
        border-radius: 0;
        border: none;

        padding: 0 15px;


      }
      ::placeholder{
        padding-left: 15px;
      }
      input:not([type=submit]){
        background-color:#ececec;
      }
    }

  }

  .table {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    margin: 65px 0;
    width: 100%;

    &__title {
      margin-top: 60px;
    }

    &__old-price {
      text-decoration: line-through #FF0000;
      margin-bottom: 5px;
    }

    &__new-price {
      color: $color-yellow;
    }

    &__new-price::after {
      content: " đ";
      font-size: 16px;
      color: $color-yellow;
    }

    &__select-pack {
      width: 176px;
      margin: 0 auto;
    }

    &__item {
      padding:  15px 0 15px 33px;
      vertical-align: bottom;
    }

    .first {
      padding-top: 39px;
    }

    .last {
      padding-bottom: 50px;
    }

    .bn {
      padding: 5px 20px;
      margin-top: 19px;
      margin-bottom: 84px;
    }

    .column-three {
      background-color: rgba(255, 204, 0, 0.1);
      position: relative;
    }

    &__popular {
      margin-bottom: 0;
      position: absolute;
      top: -36px;
      background-color: #ffcc00;
      color: $color-white;
      width: 100%;
      left: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &__content {
      margin: 5px auto 5px auto;
    }

    thead{
      border-bottom: 1px solid $color-gray-BD;
    }
  }
  .collapse{
    display: none;
    &.show{
      display: block;
    }
  }

  .card {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    border-radius: 4px;
    &:last-child{
      margin-bottom: 40px;
    }

    &__hide-plan {
      display: block;
      margin: 0 auto;
      padding-top: 0;
      border-top: 1px solid $color-gray-BD;
      width: 90%;
      border-radius:  0;
    }

    &__popular {
      position: relative;
      border-radius: 4px;
    }

    &__popular-title {
      margin-bottom: 0;
      position: absolute;
      top: -25px;
      background-color: #ffcc00;
      color: $color-white;
      width: 100%;
      left: 0;
    }

    &__title {
      margin-top: 27px;
    }

    &__old-price {
      text-decoration: line-through #FF0000;
      margin-bottom: 5px;
    }
    &__subtitle{
      margin-bottom: 5px;
    }
    &__new-price {
      color: $color-yellow;
    }

    &__new-price::after {
      content: " đ";
      font-size: 16px;
      color: $color-yellow;
    }

    &__select-pack {
      width: 176px;
      margin: 0 auto;
    }

    &__header {
      background: $color-white;
      min-height: 270px;
      padding: 1px 0 0;
      //border-bottom: 1px solid $color-gray-BD;
    }

    &__footer {
      background: $color-white;

    }
    hr{
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    &__content {
      margin-top: 40px;
    }

    .bn {
      padding: 5px 20px;
      margin-top: 19px;
      margin-bottom: 29px;
    }
    .btn-link{
      display: block;
      margin: 0 auto;
      padding: 15px 0;
    }
    .card__see-plan{
      display: none !important;
      border-radius:  0;
      border-top: 1px solid $color-gray-BD;
      width: 90%;
      &.collapsed{
        display: block !important;
      }
    }

  }
}
