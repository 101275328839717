.home-ph{
  .banner-home{
    background-position: bottom !important;
    &__title{
      margin: 0 19%;
    }
    &__content{
      margin: 0 19%;
    }
    &__img{
      margin-bottom: -309px;
    }
    @include media-breakpoint-down(sm){
      &__title{
        margin: 0 10%;
      }
      &__content{
        margin: 0 10%;
      }
    }
  }
  .count-web{
.text-span{
  font-weight: 800;
  line-height: 52px;
  span{
    font-size: 144px;
    color: #FFC000;
  }
  @include media-breakpoint-down(lg){
    span{
      font-size: 103px;
    }
  }
}
  }
  .career{
    .wp-block-column{
      .wp-block-csm-block-card-section:not(:first-child){
        margin-top: -50px !important;
      }
    }
    @include media-breakpoint-down(md){
      padding-top: 60px;
      .wp-block-column{
        flex-basis: 50% !important;
        flex-grow: 0 !important;
        margin-top: -60px;
        .wp-block-csm-block-card-section{
          align-items: flex-start !important;
        }
      }
      .wp-block-column:last-child{
        flex-basis: 100% !important;
        display: grid;
        grid-template-columns: 50% 50%;
        .wp-block-csm-block-card-section:not(:last-child){
          margin-top: 0 !important;
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    .wp-block-image img{
      height: auto;
    }
  }
}
