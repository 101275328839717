.form-group {
  margin-bottom: 15px;
  label{
    margin-bottom: 0;
  }
  label.error {
    color: red;
    font-size: 12px;
    margin-left: auto;
    position: absolute;
    font-weight: 300;
  }
}



// Linear Gradient Mixin
@mixin horizontal-gradient($from, $to, $stop: 100%) {
  background-color: #BDBDBD;
}

// Basic form styling
.form-group {
  position: relative;
  padding-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;
  label {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }
  .toggle-password{
    position: absolute;
    bottom: 15px;
    right: 9px;
    z-index: 3;
  }
}

.form-control {
  border: 0;
  border-radius: 0;
  padding-left: 0;
  background-color: transparent;
  position: relative;
  z-index: 2;
  font-size: 18px;
  color: $color-dark;
  appearance: auto;

  &:not(textarea) {
    height: 44px;
  }
  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
}

.line {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  @include horizontal-gradient($colour-primary, $colour-secondary);

  &:after {
    content: "";
    height: 2px;
    position: absolute;
    display: block;
    background: $color-dark;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
    transform-origin: 0 0;
  }
}

.form-control:focus {
  ~ .line {
    &:after {
      transform: scaleX(1);
    }
  }
}
.form-control.error:focus {
  ~ .line {
    &:after {
      background: red;
    }
  }
}
.form-control.error {
  ~ .line {
    background: red;
  }
}

// Remove Bootstrap 4 height from select
select.form-control:not([size]):not([multiple]) {
  height: 44px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #BDBDBD !important;
  font-weight: 300;
  font-size: 16px;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #BDBDBD !important;
  opacity: 1;
  font-weight: 300;
  font-size: 16px;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #BDBDBD !important;
  font-weight: 300;
  font-size: 16px;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #BDBDBD !important;
  font-weight: 300;
  font-size: 16px;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #BDBDBD !important;
  font-weight: 300;
  font-size: 16px;
}

::placeholder { /* Most modern browsers support this now. */
  color: #BDBDBD !important;
  font-weight: 300;
  font-size: 16px;
}
label.error{
  color:#cc0000;
}
