html {
  font-size: 100%;
}
*{
  outline: none!important;
}
body{
  font-family: 'Roboto', sans-serif;
  @include font-size(16px);
  font-weight: 300;
  color: $black;
}
a{
 &:hover{
   color: $yellow;
 }
}
@for $i from 1 through 6 {
  h#{$i} {
    font-family: 'Barlow', sans-serif;
  }
}
//rfs
h1{
  @include font-size(40px);
}
h2{
  @include font-size(24px);
}
h3{
  @include font-size(18px);
}

li{
    list-style: none;
  }