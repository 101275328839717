.social-box {
  img {
    width: 100%;
  }
}

.thumbnail {
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    z-index: 2;
    color: #fff;
    font-size: 40px;
    top: 38%;
    font-weight: 800;
    left: 9%;
  }
}




.modal-sign {
  .modal-content{
    border-radius: 10px;
    overflow: hidden;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 10;
  }

  @include media-breakpoint-up(lg) {
    .modal-lg {
      max-width: 1000px;
    }
  }

  .form_sign {
    padding: 90px 52px;

    .title {
      font-weight: 800;
      font-size: 36px;
      text-align: center;
      color: $color-dark;

    }
  }
  ._form {
    #registerForm, #loginForm {
      margin-top: 30px;

      .remember {
        margin-bottom: 10px;

        .form-check {
          margin-left: 20px;

          label {
            color: #202020;
          }
        }

        .forgot {
          display: block;
          text-align: right;
          color: #202020;
        }
      }


      .bn {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 16px;
      }
    }

    .choose {
      text-align: center;
      color: #BDBDBD;
      margin-bottom: 5px;
    }

    .question {
      margin-top: 10px;
      text-align: center;
      color: $color-dark;

      a {
        color: $color-yellow;
      }
    }
  }
}