.tien-ich-ph,.single-tien_ich{
  .img-fluid{
    width: 100%;
  }
  .title{
    margin-top: 15px;
    a{
      text-decoration: none;
      color: $color-dark;
    }
    @include media-breakpoint-down(md){
      font-size: 16px;
    }
  }
  p.price{
    color: $color-yellow;
    font-weight: 500;
    font-size: 18px;
  }

  .other-ultilities{
    margin-bottom: 75px;
  }

  .wp-block-media-text{
    margin-top: 75px;
    margin-bottom: 80px;
    &__media img{
      height: 100%;
    }
    .wp-block-button__link{
      font-weight: 500;
      padding: 6px 27px;
      margin-bottom: 30px;
    }
    ul{
      padding-left: 15px;
    }
    li{
      margin-bottom: 20px;
      &::before {
        content: "\2022";
        color: $yellow;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
    @include media-breakpoint-down(md){
      .wp-block-media-text__content{
        padding: 30px 0 !important;
      }
      .wp-block-media-text__content>h2:first-child,.wp-block-media-text__content h2:nth-child(2),.wp-block-buttons{
        text-align: center;
      }
    }
  }
}