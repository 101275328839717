.navbar{
  color: $white;
  .btn{
    color: $white;
  }
}
.nav-menu{
  margin: 0;
  padding: 0;
  height: 60px;
  li{
    padding-left: 15px;
    padding-right: 15px;
    list-style-type: none;
    &:first-child{
      padding-left: 0;
    }
  }
  a{
    color: $white;
    text-decoration: none;
    &:hover{
      color: $yellow;
    }
  }

}
.current-menu-item a{
  color: $color-yellow;
}
.nav-contact-info{
  list-style: none;
  li{
    padding-bottom: 6px;
  }
  a{
    text-decoration: none;
  }
  i.fas{
    width: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }
}
.nav-socials{
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  a{
    font-size: 18px;
  }
  li{
    display: inline-block;
    margin-left: 60px;
    &:first-child{
      margin-left: 0;
    }
  }
}