$black: #202020;
$yellow: #FFC000;
$gray: #E0E0E0;
$gray-1: #BDBDBD;
$red: #FF0047;
$color-white: #fff;
$color-dark: #202020;
$color-gray-light: #e0e0e0;
$color-gray-BD:#bdbdbd;
$color-gray:#4f4f4f;
$color-yellow: #ffc000;
$color-red: #ff0047;
$color-green: #219653;
// Base Variables
$colour-primary: rgba(0, 0, 0, 0.77);
$colour-secondary: #673AB7;
$colour-grey: #555555;
$grid-gutter-width: 30px;
$input-padding-horizontal: $grid-gutter-width / 3;
$input-padding-vertical: $grid-gutter-width / 3;