.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color:#151515;
  overflow-y: hidden;
  transition: 0.5s;
}
.openbtn{
  font-size:30px;
  cursor:pointer
}

.overlay-content {
  height: 100%;
  //top: 25%;
  width: 100%;
  text-align: center;
  .menu-body{
    height: 100%;
    width: 100%;
    position: relative;
  }
  .primary-menu{
    position: absolute;
    left: 0;
    right: 0;
    top: 76px;
    margin: 0 20px;
    .menu-item{
      a{
        line-height: 30px;
        color:#fff;
        font-weight: 500;
        font-size: 16px;
      }
    }
    .menu-item:last-child{
      border: 1px solid #ffc000;
      border-radius: 24px;
      max-width: 160px;
      margin: 22px auto 0 auto;
      a{
        color: #FFC000;
        font-size: 18px;
      }
    }
  }
  .social{
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    justify-content: center;
    bottom: 10px;
    border-top: 1px solid #BDBDBD;
    margin: 0 15px;
    .menu-item{
      a{
        margin: 0 10px;
        display: block;
        color:#fff;
        font-size: 29px;
      }
    }
  }
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: -21px;
  right: 3%;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay {overflow-y: auto;}
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
