.banner{
  background: url("../images/banner-banggia.png") ;
  background-repeat: no-repeat;
  background-size: cover ;
  padding: 150px ;
  color: $color-white;
  margin-bottom: 65px;
  @include media-breakpoint-down(md){
    padding: 150px 15px;
  }
}