footer{
  padding-top: 73px;
  padding-bottom: 1px;
  color: $white;
  .logo-brand{
    display: block;
  }
  a{
    color: $white;
  }
}
.ready{
  padding: 40px 25px;
  background: #4F4F4F;
  color: #fff;
  position: relative;
  margin-bottom: -63px;
  @include media-breakpoint-down(md){
    padding: 25px;
  }
  .btn{
    font-size: 18px;
    @include media-breakpoint-down(md){
      font-size: 16px;
    }
  }
  p{
    font-size: 30px;
    @include media-breakpoint-down(md){
      font-size: 18px;
    }
  }
}