hr.separator{
  background-color: $gray-1;
  height: 1px;
}

.highlight{
  position: relative;
  padding-top: 20px;
  &:before{
    content: '';
    height: 3px;
    background-color: $yellow;
    position: absolute;
    width: 81px;
    top: 0;
    left: 0;
  }
}
.mr-2{
  margin-right: 25px;
}