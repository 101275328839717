.wp-block-csm-block-inner-section.contact{
  margin-bottom: -70px;
  .fas{
    color: $color-white;
    margin-right: 25px;
  }
  .wp-block-social-links{
    margin-top: 90px;
    .wp-social-link{
      background-color: transparent !important;
      margin-right: 50px!important;
      a{
        padding: 0;
      }
    }
  }
  p{
    margin-bottom: 35px;
  }
}