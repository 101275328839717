// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";


// Helpers
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/bootstrap-utilities";

@import "variables";
@import "mixin";
@import "reset";
@import "vendors";
@import "components/navbar";
@import "components/buttons";
@import "components/footer";

@import "components/utilities";
@import "components/form";
@import "components/modals";
@import "components/menu-mobile";
@import "components/header";
@import "components/banner";

@import "page/home";
@import "page/list-prices";
@import "page/tien-ich";
@import "page/mini-game";
@import "page/contact";
