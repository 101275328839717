.mini-game-ph{
  .wp-block-media-text{
    &__media {
      img{
        width: 100%;
        height: auto;
      }
      @include media-breakpoint-down(md){
        text-align: center;
        margin-bottom: 20px !important;
        img{
          width: auto;
          max-width: 100% ;
        }
      }
    }
    li{
    margin-bottom: 20px;
      &::before {
        content: "✔";
        color: $yellow;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        margin-right: 1em;
      }
    }
    @include media-breakpoint-down(md){
      &__content{
        padding: 0 !important;
      }
    }
  }
  .wp-block-csm-block-inner-section.card{
    .wp-block-column{
      border-radius: 4px;
      border: 1px solid $color-gray-BD;
      padding: 40px 20px 150px;
      position: relative;
      @include media-breakpoint-down(md){
        &:not(:last-child){
          margin-bottom: 30px;
        }
      }
      &:hover{
       box-shadow: 2px 2px 16px $color-gray-BD;
        border-top: 10px solid $color-yellow;
        margin-bottom: -9px;
        @include media-breakpoint-down(md){
          margin-bottom: 30px;
          box-shadow: none;
          border-top: 1px solid $color-gray-BD;
        }
      }

      li{
        margin-bottom: 12px;
        &::before {
          content: "✔";
          color: $yellow;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
          margin-right: 1em;
        }
      }

      .wp-block-buttons{
        position: absolute;
        bottom: 75px;
        left: 0;
        right: 0;
      }
    }
  }
  .wp-block-csm-block-tabel-tab{
    border-radius: 4px;
    box-shadow:  2px 2px 20px $color-gray-BD;
     padding: 30px;
    @include media-breakpoint-down(md){
      padding: 12px;
    }
    tbody{
      tr:first-child td{
        padding-top: 20px;
      }
      p{
        margin-bottom: 0;
      }
    }
    .nav{
      font-size: 12px;
      justify-content: center;
      flex-wrap: nowrap;
      text-align: center;
      background-color: $color-gray-light;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      &-link{
        color: $color-dark;
        font-weight: 500;
        padding: 5px 15px;

      }
    }
  }

  .wp-block-csm-block-inner-section.data{
    .wp-block-table {
      box-shadow: 2px 2px 20px $color-gray-BD;
      border-radius: 4px;
    }
    table{
      td{
        padding: 20px;
      }
      tr:not(:last-child){
        border-bottom: 1px solid #e0e0e0;
      }
      thead{
        background:#e0e0e0;
        th{
          padding: 20px;
        }
      }
    }
  }
}